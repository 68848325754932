.analytic-dashboard {
    display: flex;
    justify-content: space-between;
}

#button {
    margin: 8px 0;
}

@media (max-width: 600px) {
    .analytic-dashboard {
        flex-direction: column;
        align-items: flex-start;
    }
}